// stylelint-disable all

* {
    overflow: visible !important; /* Required for FireFox pre-3.6 to print past page 1 */
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    page-break-after: avoid;
}

body * {
    float: none !important; /* Required for FireFox pre-3.6 to print past page 1 */
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 0;
    text-decoration: none;
}

body {
    padding: 30pt 50pt 40pt;
    background-color: #ffffff;
    color: #000000 !important;
}

main * {
    display: initial;
}

/*
 * Typography
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    display: block;
    width: 100%;
}

h1 {
    margin: 0 0 10pt;
    font-size: 36pt !important;
    line-height: 1.2;
    color: #000000;
}

h2 {
    margin: 0 0 10pt;
    color: #000000;
    font-size: 20pt;
    line-height: 1.2;
}

h3,
h4,
h5,
h6 {
    margin: 0 0 10pt;
    font-style: normal;
    font-weight: normal;
    font-size: 16pt;
    line-height: 1.2;
    color: #000000;
}

p {
    display: block;
    margin-bottom: 15pt;
}

a[href^="mailto"]::after,
a[href^="http"]::after {
    content: " (" attr(href) ") ";
}

a[href^="#"]::after {
    content: "";
}

a,
a:link,
a:visited,
a:hover {
    color: #000000;
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
legend {
    page-break-after: avoid;
}

table tr,
ul,
ol,
img {
    page-break-inside: avoid;
}

img {
    height: auto;
    max-width: 100%;
    margin-bottom: 30pt;
}

em {
    font-weight: normal;
    font-style: italic;
}

/*
 * Theme resets
 */
.theme--primary,
.theme--secondary,
.theme--tertiary {
    background-color: #ffffff !important;
}

.content-section__inner,
.content-section__part,
.page-header,
.page-header__inner,
.is-above-content-section .page-header__inner,
.is-above-content-section .page-header__part,
.page-header__part {
    width: 100%;
    max-width: none;
    display: block;
    padding: 0;
}

/*
 * Page header
 */
.page-header {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 0 15pt;
    background: #ffffff;
    text-align: left;
    border-bottom: 1px solid #cccccc;
}

.page-header__title {
    color: #000000;
    font-size: inherit;
}

.page-header--home .page-header__part {
    width: 100%;
    text-align: left;
}

/*
 * Ingredients list
 */

.ingredients-list__header {
    box-shadow: none;
    border-bottom: 1px solid #cccccc;
}

.ingredients-list__header::before {
    content: none;
}

.ingredients-list__inner {
    transform: none;
    margin: 15pt 0;
    padding: 0 0 15pt;
    box-shadow: none;
    border-bottom: 1px solid #cccccc;
}

.ingredients-list__inner * {
    opacity: 1;
    padding: 0;
    font-size: inherit;
}

.ingredients-list__header h3 {
    padding: 0;
}

/*
 * Toc menu
 */
.toc-wrapper::after {
    /* faux bg color */
    content: none;
}

/*
 * Index
 */
.index__substance {
    width: 100%;
    padding: 10pt 0;
}

.index__header {
    padding: 10pt 0;
    border-bottom: 1pt solid #cccccc;
}

.circle::before,
.circle::after {
    content: none;
}

/*
 * Word definition
 */
.word-definition__term::after {
    content: none;
}

/*
 * Don't print:
 */
nav,
script,
.content-illustration,
.site-header,
.site-footer,
.search-form,
.recently-visited,
.quiz,
.related-products,
.personalisation-tool,
.page-header__image,
.page-header__image img,
.subject-blocks,
.search,
.back-button {
    display: none !important;
}
